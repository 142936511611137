import type { SelectedColumn } from 'o365-modules';
import type { DataItemModel, ItemModel, RecordSourceOptions } from './types.ts';
import type DataObject from './DataObject.ts';
import type { DataColumn } from 'o365-datagrid';
import { BaseSelectionControl } from 'o365-modules';

import { $t } from 'o365-utils';

export default class DataObjectSelectionControl<T extends ItemModel = ItemModel> extends BaseSelectionControl<T> {
    private _dataObject: DataObject<T>;
    private _selectedUniqueKeys: Set<number | string> = new Set();
    private _selectedPrimKeys: Set<number | string> = new Set();
    private _selectedDataItems: Set<DataItemModel<T>> = new Set();

    private _cancelDataLoaded?: () => void;
    private _cancelDynamicDataLoaded?: () => void;

    private async _getSelectedRows() {
        const { promiseAlert } = await import('o365-vue-services');
        const options = { ...this._dataObject.recordSource.getOptions(), maxRecords: -1 };
        const uniqueField = this._dataObject.fields.uniqueField;
        const retrieveRowsPromise = this._dataObject.dataHandler.retrieve(options);
        this._alertInstance = promiseAlert($t("Copying rows..."), retrieveRowsPromise, 'info');
        const allData: T[] | DataItemModel<T>[] = await retrieveRowsPromise;

        if (this._selectedUniqueKeys.size === allData.length) {
            return allData;
        } else {
            return allData.filter(item => this._selectedUniqueKeys.has(item[uniqueField!]));
        }
    }

    private _clearSelection: boolean = true;
    private _selectionSave: boolean = false;

    selectAllLoading: boolean = false;
    isCopying: boolean = false;

    private _alertInstance: {
        updateMessage: (newMessage: string) => void;
        close: () => void;
    } | null = null;

    get selectedDataItems() {
        return this._selectedDataItems;
    }

    get selectedUniqueKeys() {
        return this._selectedUniqueKeys;
    }
    get selectedPrimKeys() {
        return this._selectedPrimKeys;
    }

    get selectionSave() {
        return this._selectionSave;
    }

    get selectedRows() {
        if (this._dataObject?.batchDataEnabled) {
            return [...this._dataObject.batchData.data.filter(row => row.isSelected), ...this._dataObject.data.filter(row => row?.isSelected)];
        } else {
            return this._dataObject?.data.filter(row => row?.isSelected);
        }
    }

    constructor(pOptions: { dataObject: DataObject<T> }) {
        super();
        this._dataObject = pOptions.dataObject;

        this._cancelDynamicDataLoaded = this._dataObject.on('DynamicDataLoaded', (clear: boolean, newData: DataItemModel<T>[]) => {
            this._clearSelection = clear;
            if (!this._clearSelection || this._selectionSave) {
                this.selectRowsAfterLoad(newData);
            }
        })
        this._cancelDataLoaded = this._dataObject.on('DataLoaded', (data) => {
            if (this._clearSelection && !this._selectionSave) {
                this.clearSelectionsArrays();
            } else {
                this.selectRowsAfterLoad(data);
            }
        })
    }

    /**
     * Clean up for dataObject event listeners
     */
    destroy() {
        if (this._cancelDataLoaded) {
            this._cancelDataLoaded();
            this._cancelDataLoaded = undefined;
        }
        if (this._cancelDynamicDataLoaded) {
            this._cancelDynamicDataLoaded();
            this._cancelDynamicDataLoaded = undefined;
        }
    }

    clearSelectionsArrays() {
        this._selectedUniqueKeys.clear();
        this._selectedPrimKeys.clear();
        this._selectedDataItems.clear();
        this.allRowsSelected = false;
    }

    /**
     * Enables selection save to keep selection after dataObject has been reloaded
     */
    enableKeepSelectionAfterLoad() {
        this._selectionSave = true;
    }

    /**
     * Check if all rows are selected
     */
    isAllRowsSelected() {
        let length = this._dataObject.data.length;
        if (this._dataObject.batchDataEnabled) {
            length += this._dataObject.batchData.data.length;
        }


        if (this._dataObject.state.rowCount! > length) {
            return this._selectedUniqueKeys.size === this._dataObject.state.rowCount;
        } else {
            return this._selectedUniqueKeys.size === length;
        }
    }

    /**
     * Check if some rows are selected
     */
    isSomeRowsSelected() {
        const length = this._dataObject.batchDataEnabled
            ? this._dataObject.batchData.data.length
            : this._dataObject.data.length;

        if (this._selectedUniqueKeys.size === 0) { return false; }
        if (this._dataObject.state.rowCount! > length) {
            if (this._selectedUniqueKeys.size < this._dataObject.state.rowCount!) { return true; }
            return false;
        }
        if (this._selectedUniqueKeys.size < length) { return true; }
        return false;
    }

    /**
     * After dataObject is reloaded, it runs through all selectedUniqueKeys and sets isSelected to true
     */
    selectRowsAfterLoad(data: DataItemModel<T>[]) {
        this._selectedUniqueKeys.forEach(key => {
            const row = data.find(row => row[row.uniqueKeyField!] === key);
            if (row) {
                row.isSelected = true;
            }
        })
    }

    /**
     * Handles selection event:
     *      - Checks whether the selected row is in storage or the new row panel
     *      - Depending on the value to be set adds/deleted the row to/from selectedUniqueKeys set
     *      - Checks if all rows are selected.
     */
    onSelection(pIndex: number, pValue: boolean): void {
        let item: DataItemModel<T>;

        const getUniqueValue = (pItem: DataItemModel<T>) => {
            if (pItem.uniqueKeyField) {
                return pItem[pItem.uniqueKeyField];
            } else {
                return pItem.key;
            }
        }

        if (pIndex < 0 && this._dataObject.batchDataEnabled) {
            const storageIndex = this._dataObject.batchData.getInversedIndex(pIndex);
            item = this._dataObject.batchData.storage.data[storageIndex] ?? null;
        } else {
            item = this._dataObject.storage.data[pIndex];
        }

        if (item == null) { return; }

        if (pValue) {
            this._selectedUniqueKeys.add(getUniqueValue(item));
            if (item.PrimKey) this._selectedPrimKeys.add(item.PrimKey);
        } else {
            this._selectedUniqueKeys.delete(getUniqueValue(item));
            if (item.PrimKey) this._selectedPrimKeys.delete(item.PrimKey);
        }


        if (pValue) {
            this._selectedDataItems.forEach(savedItem => {
                if (getUniqueValue(savedItem) == getUniqueValue(item)) {
                    this._selectedDataItems.delete(savedItem);
                }
            })
            this._selectedDataItems.add(item);
        } else {
            this._selectedDataItems.delete(item);
        }


        this.allRowsSelected = this.isAllRowsSelected();
    }

    getSelection(columnArray: DataColumn[], rowData?: DataItemModel<T>[], pOptions?: {
        valueResolve?: (pColumn: any, pRow: DataItemModel<T>) => [string, any],
    }) {
        if (this.isValidSelection) {
            const srcRows = rowData ?? this._dataObject?.data;
            if (!srcRows) { return []; }
            const rows = this.getRows(srcRows, "G");
            const columns = this.getColumns(columnArray);
            if (rows == null || columns == null) { return; }
            const selected: Record<string, any>[] = [];
            rows.forEach((row, index) => {
                selected[index] = {};
                selected[index].index = row.index;
                columns.forEach(col => {
                    if (pOptions?.valueResolve) {
                        const resolvedValues = pOptions.valueResolve(col, row);
                        if (Array.isArray(resolvedValues)) {
                            const [fieldName, fieldValue] = resolvedValues;
                            selected[index][fieldName] = fieldValue;
                        } else if (typeof resolvedValues == 'object') {
                            Object.entries(resolvedValues).forEach(([fieldName, fieldValue]) => {
                                if (fieldName == '$filter' && typeof selected[index].$filter == 'object' && typeof fieldValue == 'object') {
                                    selected[index][fieldName] = { ...selected[index][fieldName], ...fieldValue };
                                } else {
                                    selected[index][fieldName] = fieldValue;
                                }
                            });
                        }
                    } else {
                        selected[index][col.name] = row[col.name];
                    }
                });
            });
            return selected;
        } else {
            return [];
        }
    }

    getSelectedRowsData(columnArray: SelectedColumn[]) {
        const selectedRows = this._dataObject?.data.filter((item) => item.isSelected);
        const selection: Record<string, any>[] = [];
        if (selectedRows) {
            selectedRows.forEach((row) => {
                let createdRow: Record<string, any> = {}
                columnArray.forEach((item) => {
                    const fieldName = typeof item === 'string' ? item : item.name;
                    if (!fieldName.startsWith('o365')) {
                        createdRow[fieldName] = row[fieldName];
                    }
                });
                selection.push(createdRow);
            });
        }
        return selection;
    }

    async selectAll(setSelectionTo: boolean = true) {
        this._selectedUniqueKeys.clear();
        this._selectedPrimKeys.clear();
        this._selectedDataItems.clear();

        this.allRowsSelected = setSelectionTo;
        this.selectAllLoading = this.allRowsSelected === true ? true : false;
        let combinedCount = 0;
        const uniqueKeyField = this._dataObject.fields.uniqueField;
        this._dataObject.data.forEach((item) => { // storage, not data
            if (item == null) { return; }
            item.state.isSelected = setSelectionTo;
            combinedCount++;
            this._dataObject.emit('ItemSelected', item.index, setSelectionTo);
            if (setSelectionTo) {
                this._selectedUniqueKeys.add(item[item.uniqueKeyField!])
                this._selectedPrimKeys.add(item["PrimKey"])
                this._selectedDataItems.add(item)
            }
        });
        if (this._dataObject.batchDataEnabled) {
            this._dataObject.batchData.data.forEach(item => {
                if (item == null) { return; }
                if (item.isNewRecord) { return; }
                item.state.isSelected = setSelectionTo;
                combinedCount++;
                if (setSelectionTo) {
                    this._selectedUniqueKeys.add(item[item.uniqueKeyField!])
                    this._selectedPrimKeys.add(item["PrimKey"])
                    this._selectedDataItems.add(item)
                }
            });
        }
        if (!setSelectionTo) {
            return
        } else if (this._dataObject.state.rowCount == null || combinedCount < this._dataObject.state.rowCount) {
            const { confirm } = await import('o365-vue-services');
            let rejected = null;
            if (this._dataObject.state.rowCount != null && this._dataObject.state.rowCount > 200) {
                await confirm({
                    message: $t(`Are you sure you want to select ${this._dataObject.state.rowCount} rows?`)
                }).then(() => {
                    rejected = false;
                }, (rej) => {
                    rejected = rej['Canceled'];
                });
                if (rejected) {
                    this.selectAll(false);
                    this.selectAllLoading = false;
                    return;
                }
            }
            const records = await this._dataObject.recordSource.getAllUniqueKeys({ withSortOrder: true });
            this._dataObject.state.rowCount = records.length;
            if (records.length !== 0 && records.length > 200 && rejected == null) {
                await confirm({
                    message: $t(`Are you sure you want to select ${records.length} rows?`)
                }).then(() => { }, (rej) => {
                    rejected = rej['Canceled'];
                });
                if (rejected) {
                    this.selectAll(false);
                    this.selectAllLoading = false;
                    return;
                }
            }
            records.forEach(record => this._selectedUniqueKeys.add(record[uniqueKeyField!]))
            records.forEach(record => this._selectedPrimKeys.add(record["PrimKey"]))
            this.selectAllLoading = false;
            import('o365-vue-services').then(services => {
                services.alert(`Selected ${records.length} records`, 'info', {
                    autohide: true,
                    delay: 1000,
                    slimVersion: true
                });
            });
            // this._selectedDataItems.clear();
        }
        this.selectAllLoading = false
    }

    /**
     * Get selected rows in an async call.
     * @param pParams - additional record source options for the retrieve
     */
    async getSelectedRows(pParams?: RecordSourceOptions) {
        if (!this._selectedUniqueKeys.size) { return []; }
        const generateOptions = () => {
            let options = { ...this._dataObject.recordSource.getOptions() };
            if (pParams) {
                options = { ...options, ...pParams };
            }
            if (this._dataObject.fields.uniqueField && !options.fields?.find((field) => field.name == this._dataObject.fields.uniqueField)) {
                options.fields?.push({
                    name: this._dataObject.fields.uniqueField
                })
            }
            return options;
        }
        if (this.isAllRowsSelected()) {
            const options = generateOptions();
            options.maxRecords = -1;
            return await this._dataObject.dataHandler.retrieve(options);
        } else {
            const promises: ReturnType<DataObject<T>['recordSource']['retrieve']>[] = [];
            const selectedUniqueKeys = Array.from(this.selectedUniqueKeys);
            while (selectedUniqueKeys.length) {
                const options = generateOptions();
                const keys = selectedUniqueKeys.splice(0, 750);
                options.skipAbortCheck = true;
                options.filterString = `[${this._dataObject.fields.uniqueField}] IN ('${keys.join("','")}')`;
                options.maxRecords = keys.length;
                promises.push(this._dataObject.dataHandler.retrieve(options));
            }
            const data = await Promise.all(promises);
            const result = [];
            for (const batch of data) {
                result.push(...batch);
            }
            return result;
        }
    }
    /** Gets selected rows async funcion
     * @pFields - Array<string> - Array list of fields
     */
    async getSelectedFields(pFields: Array<string>) {
        const options: any = {};
        if (pFields) {
            options.fields = pFields.map(x => { return { name: x } });
        }
        return this.getSelectedRows(options);
    }

    async copySelection(withHeaders = false, gridColumns: SelectedColumn[], copyAsJson = false) {
        this.isCopying = true;

        let selectedRows: T[] | DataItemModel<T>[] | null = null;
        if (this._selectedDataItems.size > 0) {
            selectedRows = Array.from(this._selectedDataItems);
        } else if (this._selectedUniqueKeys.size > 0 && selectedRows == null) {
            selectedRows = await this._getSelectedRows();
        } else {
            selectedRows = this._dataObject.data.filter(item => item && item.isSelected);
        }
        if (selectedRows == null) { return; }
        const options = { isFullRow: selectedRows.length };
        const columns = this.getColumnsForCopy(selectedRows, gridColumns, options);
        let rows = selectedRows.length ? selectedRows : this.getRows(this._dataObject.data, 'G');
        if (rows == undefined && this._dataObject.batchDataEnabled) {
            rows = this.getRows(this._dataObject.batchData.data, 'N');
        }
        if (columns == null || rows == null) { return; }
        let result = '';
        const jsonCopy: Record<string, any>[] = [];
        if (columns && columns.length > 0 && withHeaders) {
            columns.forEach((col, colIndex) => {
                result += col.caption;
                if (colIndex < columns.length - 1) { result += '\t'; }
            });
            result += '\n';
        }
        rows.forEach((row, rowIndex) => {
            let jsonColumnData: Record<string, any> = {};
            columns.forEach((col, colIndex) => {
                // if (col.overwriteCopy) {
                //     col.overwriteCopy('Hello')
                // }
                if (col.unbound) {
                    if (col.getCopyValue) {
                        result += col.getCopyValue(row, 0) ?? '';
                        jsonColumnData[col.name] = col.getCopyValue(row, 0);
                    }
                } else {
                    const value = row[col.name];
                    if (value) {
                        let formatedValue = this.formatForCopyValue(value, col.type);;
                        jsonColumnData[col.name] = formatedValue;
                        result += formatedValue;
                    }
                }
                if (colIndex < columns.length - 1) { result += '\t'; }
            });

            if (copyAsJson || options.isFullRow) {
                this._dataObject.fields.fields.forEach(field => {
                    if (jsonColumnData[field.name] == undefined) {
                        jsonColumnData[field.name] = row[field.name];
                    }
                });
            }

            jsonCopy.push(jsonColumnData);
            if (rowIndex < rows.length - 1) { result += '\n'; }
        });
        result = copyAsJson ? JSON.stringify(jsonCopy) : result;

        if (!options.isFullRow || copyAsJson || window.ClipboardItem == null) {
            navigator.clipboard.writeText(result)
                .then()
                .catch((error) => console.error("error", error));
        } else {
            const type = 'text/plain';
            const textBlob = new Blob([result], { type });
            const jsonBlob = new Blob([
                JSON.stringify(jsonCopy)
            ], { type: 'web text/json' });
            const clipboardItem = new ClipboardItem({
                [type]: textBlob,
                'web text/json': jsonBlob
            });

            navigator.clipboard.write([clipboardItem])
                .then()
                .catch((error) => console.error("error", error));
        }

        if (this._alertInstance) {
            this._alertInstance.updateMessage($t(`${rows.length} ${rows.length > 1 ? 'rows' : 'row'} added to clipboard.`));
            window.setTimeout(() => {
                this._alertInstance!.close();
            }, 2000);
        }
    }

    handleJsonPaste(text: string, columns: DataColumn[]) {
        type JsonPasteObject = {
            fields?: { name: string }[];
            data?: any[][]
        };
        let jsonObject: JsonPasteObject | null | Record<string, any>[] = null;
        try {
            jsonObject = JSON.parse(text);
            if (typeof jsonObject !== 'object' || jsonObject == null) { throw new Error('Invalid JSON string'); }
            const isJsonObject = (pObj: any): pObj is JsonPasteObject => {
                return pObj?.hasOwnProperty('data') && pObj?.hasOwnProperty('fields');
            };
            if (isJsonObject(jsonObject)) {
                const vJsonObject: Record<string, any>[] = [];
                const vFields = jsonObject["fields"]!;
                jsonObject["data"]!.forEach((row) => {
                    const vTmp: Record<string, any> = {};
                    row.forEach((val, index) => {
                        vTmp[vFields[index].name] = val;
                    })
                    vJsonObject.push(vTmp);
                })

                jsonObject = vJsonObject;
            }
            if (Array.isArray(jsonObject) && this._dataObject.batchDataEnabled && this._dataObject.batchData.data.length) {
                jsonObject.forEach((pastedRow) => {

                    // should set all fields except uniqueField
                    let existingBatchItem = this._dataObject.batchData.data.find((item) => {
                        return item.isEmpty;
                    });
                    if (existingBatchItem) {
                        Object.keys(existingBatchItem.item).forEach((item) => {
                            //   if (!columns.find((column) => column.field === item && !column.hide)) return;
                            if (!this._dataObject.fields[item]) return;
                            if (this._dataObject.fields.uniqueField == item) return;
                            if (item == "PrimKey") return;
                            if (this._dataObject.fields[item]?.dataType == "datetime2") {
                                pastedRow[item] = pastedRow[item] == null ? null : new Date(pastedRow[item]);
                            }
                            if (existingBatchItem && item && pastedRow[item]) {
                                (existingBatchItem[item] as any) = pastedRow[item];
                                existingBatchItem.disableSaving = true;
                            }
                        });
                    } else {
                        throw new Error('Could not find existing new record');
                    }
                });
            }
        } catch (error) {
            return false;
        }
        return jsonObject;
    }


    async pasteSelection(event: KeyboardEvent, columns: DataColumn[]) {
        try {
            this._isPasting = true;
            let text = '';
            if (window.navigator.clipboard.read == null) {
                // Firefox
                text = await window.navigator.clipboard.readText(); // Seems like this is only supported in extensions for now, but nightly supports it on pages too
                // Need a temp alternative for firefox
            } else {
                // WebKit
                const data = await window.navigator.clipboard.read();
                if (data[0] == null) { return; }
                if (data[0].types.includes('web text/json')) {
                    // Clipboard contains data in json, use that instead of plain text
                    const blob = await data[0].getType('web text/json')
                    text = await blob.text();
                } else if (data[0].types.includes('text/plain')) {
                    const blob = await data[0].getType('text/plain')
                    text = await blob.text();
                } else {
                    return;
                }
            }

            if (this.handleJsonPaste(text, columns)) {
                // JSON paste successful, skip default paste
                return;
            }

            const splitRows = text.split(/\r/);
            const rows = splitRows.filter((row, index) => {
                if (index < (splitRows.length - 1) && row == '\n') {
                    return true;
                } else if (index === (splitRows.length - 1) && row == '\n') {
                    return false;
                } else {
                    return true;
                }
            })
            // const rows = text.split(/\r?\n/).filter(row => row.trim() !== '');
            const parsedText = rows.map(row => row.split('\t'));

            // const parsedText = text.split('\r\n').map(row => row.split('\t'));
            const pasteSelection = this.determinePasteSelection(event);
            const disableAutoSaving = rows.length > 1;
            if (pasteSelection && pasteSelection.startAtIndex != null && pasteSelection.columnStartAtIndex != null && this.areaselection!.start.container === 'N' &&
                this._dataObject.batchDataEnabled && this._dataObject.batchData.data.length) {
                this.batchDataPaste(parsedText, disableAutoSaving, columns);
            } else {
                const selectedRows = this.getRows(this._dataObject.data, "G");
                const selectedColumns = this.getColumns(columns);
                if (selectedRows == null || selectedColumns == null) { return; }
                this.gridDataPaste(parsedText, columns, selectedRows, selectedColumns);
                this._dataObject.save();
            }
            return true;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            this._isPasting = false;
        }
    }

    private batchDataPaste(
        parsedText: string[][],
        disableAutoSaving: boolean,
        columns: DataColumn[],
    ) {
        const parsedColumnLength = this.parsedColumnLength(parsedText);
        this.modifyAreaSelection(parsedColumnLength, parsedText.length, false);
        parsedText.forEach((pastedRow, index) => {
            let existingBatchItem = this._dataObject.batchData.data.find((item) => {
                //return item.index === pasteSelection.startAtIndex! + index;
                return this._dataObject.batchData.getInversedIndex(item.index) === this.areaselection!.start.y + index;
                // return this._dataObject.batchData.getInversedIndex(item.index) === pasteSelection.startAtIndex! + index;
            });
            if (existingBatchItem) {
                if (disableAutoSaving) {
                    existingBatchItem.disableSaving = disableAutoSaving;
                }
                const filteredColumns = columns.filter((col, colIndex) => {
                    return colIndex >= this.areaselection!.start.x && !col.hide && (this.isEditable(col, existingBatchItem) || col.onPaste);
                });
                let systemColumns = 0;
                filteredColumns.forEach((col, colIndex) => {
                    if (existingBatchItem == null) { return; }
                    if (col.name.startsWith('o365')) { systemColumns++; }
                    if ((this.isEditable(col, existingBatchItem) || col.onPaste) && (pastedRow.length + systemColumns) > colIndex) {
                        const pastedValue = this.formatForPasteValue(pastedRow[colIndex - systemColumns], col.type);
                        if (col.onPaste) {
                            col.onPaste(existingBatchItem, pastedValue, col);
                        } else {
                            (existingBatchItem[col.name] as any) = pastedValue;
                        }
                    }
                });
            }
        });
    }

    gridDataPaste(parsedText: string[][], columns: DataColumn[], selectedRows: DataItemModel<T>[], selectedColumns: DataColumn[]) {
        let parsedColumnLength: number | undefined = undefined;
        parsedColumnLength = this.parsedColumnLength(parsedText);
        if (parsedText.length === selectedRows.length && (parsedColumnLength && (parsedColumnLength === selectedColumns.length))) {
            const sel = this.getSelectionRange();
            if (selectedRows.length == 1 && selectedColumns.length == 1 && selectedColumns[0].editable && !selectedColumns[0].cellRenderSlot && selectedColumns[0].type == "string" && sel && sel.diff) {
                const col = selectedColumns[0];
                const row = selectedRows[0];
                const text = parsedText[0][0];
                let vValue = col.name.startsWith("Property.") ? row.properties[col.name.split(".")[1]] : row[col.name];
                vValue = vValue.substring(0, sel.start) + text + vValue.substring(sel.end, vValue.length);
                if (col.name.startsWith("Property.")) {
                    (row.properties[col.name.split(".")[1]] as any) = this.formatForPasteValue(vValue, col.type);
                } else {
                    this.applyValue(row, col, vValue);
                }
                return;
            }
            selectedRows.forEach((row, rowIndex) => {
                selectedColumns.forEach((col, colIndex) => {
                    if (col.editable) {
                        if (col.name.startsWith("Property.")) {
                            (row.properties[col.name.split(".")[1]] as any) = this.formatForPasteValue(parsedText[rowIndex][colIndex], col.type);
                        } else {
                            this.applyValue(row, col, parsedText[rowIndex][colIndex]);
                        }
                    }
                });
            });
        } else if (parsedText.length >= selectedRows.length && (parsedColumnLength && (parsedColumnLength > selectedColumns.length))) {
            this.modifyAreaSelection(parsedColumnLength, parsedText.length, true, selectedColumns.length, selectedRows.length);

            const updatedSelectedRows = this.getRows(this._dataObject.data, "G")!;
            const updatedSelectedColumns = this.getColumns(columns)!;

            updatedSelectedRows.forEach((row, rowIndex) => {
                if (parsedText[rowIndex] == undefined) { return; }
                updatedSelectedColumns.forEach((col, colIndex) => {
                    const pastedValue = parsedText[rowIndex][colIndex];
                    if (pastedValue == null) { return; }
                    this.applyValue(row, col, parsedText[rowIndex][colIndex]);
                });
            })
        } else if (parsedText.length <= selectedRows.length && parsedText.length > 1 && (parsedColumnLength && (parsedColumnLength <= selectedColumns.length))) {
            selectedRows.forEach((row, rowIndex) => {
                if (parsedText[rowIndex] == undefined) { return };
                selectedColumns.forEach((col, colIndex) => {
                    this.applyValue(row, col, parsedText[rowIndex][colIndex]);
                });
            });
            this.modifyAreaSelection(parsedColumnLength, parsedText.length, false);
        } else if (parsedColumnLength != null) {
            selectedRows.forEach((row, rowIndex) => {
                selectedColumns.forEach((col, colIndex) => {
                    if (this.isEditable(col, row) || col.onPaste) {
                        const repeatingRow = rowIndex - Math.floor(rowIndex / parsedText.length) * parsedText.length;
                        const repeatingCol = colIndex - Math.floor(colIndex / parsedColumnLength!) * parsedColumnLength!;
                        this.applyValue(row, col, parsedText[repeatingRow][repeatingCol]);
                    }
                });
            });
        }
    }

    private getSelectionRange() {
        const sel = window.getSelection();
        if (!sel) return null;
        const range = sel.getRangeAt(0).cloneRange();
        return {
            start: range.startOffset,
            end: range.endOffset,
            diff: range.endOffset - range.startOffset
        }
    }

    async selectRange(pValue: boolean, pStart: number, pEnd: number) {
        if (pStart > pEnd) { throw new TypeError('End cannot be greater than start in selection range'); }
        let hasMissingRowsInRange = false;
        for (let i = pStart; i <= pEnd; i++) {
            const item = this.areaselection!.start.container === 'N' ? this._dataObject.batchData.data[i] : this._dataObject.data[i];
            if (item == null) {
                hasMissingRowsInRange = true;
            } else {
                item.isSelected = pValue;
            }
        }
        if (hasMissingRowsInRange) {
            const fields = [{ name: 'PrimKey' }];
            this._dataObject.recordSource.appendSortByFields(fields);
            const data: string[] = (await this._dataObject.recordSource.retrieve({
                fields: fields,
                maxRecords: pEnd - pStart,
                skip: pStart,
            })).map(item => item.PrimKey);
            data.forEach(key => {
                const hasUniqueKey = this._selectedUniqueKeys.has(key);
                if (pValue && !hasUniqueKey) {
                    this._selectedUniqueKeys.add(key);
                } else if (!pValue && hasUniqueKey) {
                    this._selectedUniqueKeys.delete(key);
                }
            });
        }

        this.allRowsSelected = this.isAllRowsSelected();
    }
}